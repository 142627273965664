<template>
  <div class="static-text mx-auto my-12 max-w-screen-md p-5 text-gray-900 md:my-24">
    <h1 v-if="data?.title" class="static-text__title hyphens-auto break-words text-3xl md:text-5xl">
      {{ data.title }}
    </h1>
    <h4 v-if="data?.subtitle" class="static-text__subtitle text-xs md:text-base">
      {{ data.subtitle }}
    </h4>
    <div
      v-if="data?.text"
      class="static-text__content mt-8 md:mt-16"
      @click.capture.prevent="handleOutgoingDescriptionLinks($event)"
      v-html="data.text" />
  </div>
</template>

<script setup lang="ts">
import type { TitleText } from "~/types/strapi/components/block/interfaces/TitleText";

defineProps<{
  data: TitleText;
}>();

const handleOutgoingDescriptionLinks = useLinkHandler();
</script>

<style scoped>
.static-text__content :deep(a) {
  @apply hyphens-auto break-words underline md:no-underline md:hover:underline;
}
.static-text__content :deep(h2) {
  @apply text-2xl md:text-4xl;
}
.static-text__content :deep(h3) {
  @apply text-xl md:text-2xl;
}
.static-text__content :deep(h4) {
  @apply text-lg md:text-xl;
}
.static-text__content :deep(p) {
  @apply my-3 hyphens-auto break-words;
}
.static-text__content :deep(ul) {
  @apply mb-3 list-outside list-disc pl-5;
}
.static-text__content :deep(ul) > li > ul {
  @apply pl-5;
}
</style>
